import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo1 from "../../Assets/logo1.png";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SearchModal from './SearchModal'
const Navbar = ({ onHomeClick, cartItemsCount  }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [category, setcategory] = useState([]);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false); // State for search modal
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios
      .get('https://bk.idymorocco.com/api/category', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('Fetched category:', response.data); // Debugging check
        setcategory(response.data);
      })
      .catch((error) => {
        console.error('Error fetching category:', error);
      });
  }, []);

  const isLoggedIn = !!localStorage.getItem("token");

  const handleCategoryClick = (categoryId, categoryName) => {
    navigate(`/products/${categoryId}`, { state: { categoryName } });
  };

  const handleSubcategoryClick = (categoryId, subcategoryId, categoryName, subcategoryName) => {
    navigate(`/products/${categoryId}/${subcategoryId}`, { state: { categoryName, subcategoryName } });
  };

  const handleSubSubcategoryClick = (categoryId, subcategoryId, subsubcategoryId, categoryName, subcategoryName, subSubcategoryName) => {
    navigate(`/products/${categoryId}/${subcategoryId}/${subsubcategoryId}`, { state: { categoryName, subcategoryName, subSubcategoryName } });
  };

  return (
    <div className="">
      {/* Main Navbar */}
      <div className="flex justify-between items-center pl-4 pr-10 bg-white shadow-md">
        {/* Hamburger Icon (Mobile) */}
        <button
          className="text-black sm:hidden block focus:outline-none"
          onClick={toggleMenu}
        >
          <i className="fas fa-bars fa-2x"></i>
        </button>
        {/* Links (Left on Desktop) */}
        <ul className="hidden sm:flex justify-start m-8 font-hubballi space-x-12">
          <li className="nav-item">
            <Link to="/" className="nav-link hover:text-[#ccb898]" onClick={onHomeClick}>
              HOME
            </Link>
          </li>
          <li>
            <button
              id="mega-menu-full-dropdown-button"
              data-collapse-toggle="mega-menu-full-dropdown"
              onClick={toggleDropdown}
              className="nav-link hover:text-[#ccb898]"
            >
              SHOP
            </button>
          </li>
          <li className="nav-item">
            <Link to="/blog" className="nav-link hover:text-[#ccb898]">
              BLOG
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about-us" className="nav-link hover:text-[#ccb898]">
              ABOUT US
            </Link>
          </li>
        </ul>
        {/* Logo (Center) */}
        <div className=" flex justify-center sm:justify-center">
          <Link to="/" onClick={onHomeClick}>
            <img
              src={logo1}
              alt="Idy Morocco Logo"
              className="w-20 sm:w-40 ml-8 m-2 sm:mr-72 rotate-3"
            />
          </Link>
        </div>

        {/* Icons (Right on Desktop and Mobile) */}
        <div className=" icon-link flex space-x-12 items-center">
          <button onClick={openSearchModal} className="icon-link text-black">
            <i className="fas fa-search fa-xl"></i>
          </button>
          <Link to="/cart" className="relative icon-link text-black">
        <i className="fas fa-shopping-bag fa-xl"></i>
        {cartItemsCount > 0 && (
          <span className="absolute bottom-2 left-4 bg-black text-white rounded-full h-4 w-4 flex items-center justify-center text-xs">
            {cartItemsCount}
          </span>
        )}
      </Link>
          {isLoggedIn ? (
            <Link to="/account" className="icon-link text-black">
              <i className="fas fa-user-cog fa-xl"></i>
            </Link>
          ) : (
            <Link to="/login" className="icon-link text-black">
              <i className="fas fa-user fa-xl"></i>
            </Link>
          )}
        </div>
      </div>

      {/* Mobile Links */}
      {menuOpen && (
        <ul className="sm:hidden flex flex-col items-center space-y-4 py-4">
          <li className="nav-item">
            <Link to="/" className="nav-link hover:text-[#ccb898]" onClick={onHomeClick}>
              HOME
            </Link>
          </li>
          <button
            id="mega-menu-full-dropdown-button"
            data-collapse-toggle="mega-menu-full-dropdown"
            onClick={toggleDropdown}
            className="nav-link hover:text-[#ccb898]"
          >
            SHOP
          </button>
          <li className="nav-item">
            <Link to="/blog" className="nav-link hover:text-[#ccb898]">
              BLOG
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about-us" className="nav-link hover:text-[#ccb898]">
              ABOUT US
            </Link>
          </li>
        </ul>
      )}
      {isDropdownOpen && (
        <div className="grid grid-cols-5 gap-8 p-6 bg-white text-gray-800 font-hubballi">
          {category.map((category) => (
            <ul key={category.id}>
              <li className="mb-3 font-hubballi uppercase text-sm">
                <button
                  className="hover:text-[#ccb898] uppercase"
                  onClick={() => handleCategoryClick(category.id, category.name)}
                >
                  {category.name}
                </button>
              </li>
              {category.sub_category && category.sub_category.length > 0 && (
                category.sub_category.map((sub) => (
                  <li key={sub.id} className="ml-4">
                    <button
                      className=" hover:text-[#ccb898]"
                      onClick={() => handleSubcategoryClick(category.id, sub.id, category.name, sub.name)}
                    >
                      {sub.name}
                    </button>
                    {sub.sub_sub_category && sub.sub_sub_category.length > 0 && (
                      <ul className="ml-4">
                        {sub.sub_sub_category.map((subSub) => (
                          <li key={subSub.id}>
                            <button
                              className=" hover:text-[#ccb898]"
                              onClick={() => handleSubSubcategoryClick(category.id, sub.id, subSub.id, category.name, sub.name, subSub.name)}
                            >
                              {subSub.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))
              )}
            </ul>
          ))}
          <ul>
            <li className="nav-item">
              <Link to="/all-products" className="hover:text-[#ccb898] uppercase" onClick={onHomeClick}>
                ALL PRODUCTS
              </Link>
            </li>
          </ul>
        </div>
      )}
      <SearchModal isOpen={isSearchModalOpen} onClose={closeSearchModal} />
    </div>
  );
};

export default Navbar;