import React, { useState, useEffect } from "react";
import ShoppCards from "./ShoppCards"; // Adjust the import path as needed
import Navbar from "../../Components/Home/Navbar";
import Loading from "../../Components/Home/Loading";

export default function ShopPage({ currency }) {
  const [productsResponse, setProductsResponse] = useState(null);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProducts(currency);
  }, [currency]);

  const fetchProducts = async (currency, page) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://bk.idymorocco.com/api/products?page=${page}&currency=${currency}`
      );
      const data = await response.json();
      setProductsResponse(data.data); // Assuming the products are in data.data
      // setTotalPages(data.pagination.last_page); 
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  // const handleNextPage = () => {
  //   if (currentPage < totalPages) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // };

  // const handlePreviousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // };

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  return (
    <div>
      <Navbar />
      {loading ? (
        <Loading />
      ) : (
        <ShoppCards
          productsResponse={productsResponse}
          // currentPage={currentPage}
          // totalPages={totalPages}
          // handleNextPage={handleNextPage}
          // handlePreviousPage={handlePreviousPage}
          // handlePageChange={handlePageChange}
          currency={currency}
        />
      )}
    </div>
  );
}