import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/Home/HomePage";
import LoginPage from "./Pages/Auth/Loginpage";
import RegisterPage from "./Pages/Auth/Registerpage";
import BlogPage from "./Pages/Blog/Blogpage";
import Aboutuspage from "./Pages/Aboutus/Aboutuspage";
import Shoppage from "./Pages/Shop/Shoppage";
import Footer from "./Components/Home/Footer";
import Cartpage from "./Pages/Cart/Cart";
import Checkout from "./Pages/Checkout/checkout";
import Product from "./Pages/Products/productpage";
import WhatsAppIcon from "./Components/Whtspsrv/Whatsaap";
import ProductDetailPage from "./Pages/Products/ProductDetailPage";
import ProfilePage from "./Pages/Pofile/ProfilePage";
import Header from "./Components/Home/Header";
import NotFoundPage from "./Pages/NotFound/NotFoundPage";
import { CartProvider } from "./Pages/CartContext/CartContext";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Faq from "./Pages/Terms/Faq";
import PrivacyPolicy from "./Pages/Terms/PrivacyPolicy";
import ReturnsDelivery from "./Pages/Terms/ReturnsDelivery";
import TermsConditions from "./Pages/Terms/TermsConditions";
import ContactUs from "./Pages/contactus/ContactUs";


function App() {
  const [currency, setCurrency] = useState("");

  return (
    <CartProvider>
      <Router>
        <Header onCurrencyChange={setCurrency} />
        <div className="app-container">
          <Routes>
            <Route path="/" element={<HomePage currency={currency} />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/about-us" element={<Aboutuspage />} />
            <Route path="/all-products" element={<Shoppage currency={currency} />} />
            <Route path="/cart" element={<Cartpage currency={currency} />} />
            <Route path="/checkout" element={<Checkout currency={currency} />} />
            <Route path="/product/:id" element={<ProductDetailPage currency={currency} />} />
            <Route path="/account" element={<ProfilePage />} />
            <Route path="/products/:categoryId" element={<Product currency={currency} />} />
            <Route path="/products/:categoryId/:subcategoryId" element={<Product currency={currency} />} />
            <Route path="/products/:categoryId/:subcategoryId/:subsubcategoryId" element={<Product currency={currency} />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
            <Route path="/Returns-Delivery" element={<ReturnsDelivery />} />
            <Route path="/Terms-Conditions" element={<TermsConditions />} />
            <Route path="/Contact-us" element={<ContactUs />} />


            <Route path="*" element={<NotFoundPage />} />
          </Routes>

          <Footer />
          <WhatsAppIcon />
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
