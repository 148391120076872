import React, { useState, useEffect } from 'react';
import "../../Styles/Description.css";
import Loading from "../../Components/Home/Loading";
import { Link } from "react-router-dom";

const ProductPage =({ currency }) => {
  const [products, setProducts] = useState([]); // Initialize as an empty array
  const [showAll] = useState(false); // State to toggle view
  const [currentPage] = useState(1);
  const [exchangeRate, setExchangeRate] = useState(1); // Default exchange rate
  useEffect(() => {
    // Fetch products from the API
    const fetchProducts = async (currency,page) => {
      try {
        const response = await fetch(
          `https://bk.idymorocco.com/api/products?page=${page}&currency=${currency}`
        );
        const result = await response.json();
        console.log("Fetched data:", result); // Log the fetched data
        if (result.data && Array.isArray(result.data)) {
          setProducts(result.data); // Assuming the API returns last_page
        } else {
          console.error("Expected an array but got:", result.data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts(currency);
  }, [currency,currentPage]);

  const displayedProducts = Array.isArray(products) ? (showAll ? products : products.slice(0, 8)) : [];


  // const handleToggleProducts = () => {
  //   setShowAll(prevShowAll => !prevShowAll);
  // };



  return (
    <>
      
      <div className="productcard container mx-auto flex items-center flex-wrap pt-4 pb-12">
        {displayedProducts.length > 0 ? displayedProducts.map(product => (
          <div key={product.id} className="w-full md:w-1/3 xl:w-1/4 p-6 flex flex-col">
            <button className="relative max-w-xs overflow-hidden bg-cover bg-no-repeat">
             <Link to={`/product/${product.id}`}>
              <div class="relative max-w-xs overflow-hidden bg-cover bg-no-repeat">
  <img
    src={
                          product.images.length > 0
                            ? product.images[0].url
                            : `https://via.placeholder.com/300?text=${product.name}`
                        }
    class="max-w-xs transition duration-300 ease-in-out hover:scale-110"
    alt="Louvre" />
</div>
             </Link>
              <div className="pt-3 flex items-center justify-between font-hubballi">
                <p>{product.name}</p>
              </div>
              <p className="pt-3 flex items-center justify-between font-hubballi">
  {product.base_price ? product.base_price.toFixed(2) : "0.00"}{currency ? currency.toUpperCase() : "MAD"}
  {product.compare_at_price && (
    <span className="line-through text-gray-500 ml-2 font-hubballi">
      {product.compare_at_price.toFixed(2)}{currency ? currency.toUpperCase() : "MAD"}
    </span>
  )}
</p>

            </button>
          </div>
        )) : (
          <p className="text-center w-full"> <Loading /></p>
        )}
      </div>
      <div className="flex items-center justify-center">
      <Link to="/all-products" >
          <button 
            className="bg-customBlack  text-white hover:bg-white hover:text-black py-2 mt-6 px-6 rounded-md font-hubballi border border-gray-400"
          >
            VIEW ALL PRODUCTS
          </button>
        </Link>
      </div>
    </>
  );
};

export default ProductPage;
