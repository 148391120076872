// Description.js

import React from "react";
import "../../Styles/Description.css"; // Adjust the path as necessary

const Description = () => {
  return (
    <div className="description-container">
      <p className="description-text">
        We are dedicated to preserving our Moroccan heritage by offering
        carefully curated artisanal homeware, sourced directly from local
        villages and cooperatives. Each piece in our collection reflects the
        timeless skills and traditions of Moroccan artisans. By sharing these
        unique treasures with you, we provide a vital link between these skilled
        craftspeople and the modern market. This connection helps sustain their
        craft and ensures the continuation of traditional handmade artistry for
        future generations.
      </p>
      
    </div>
  );
};

export default Description;
