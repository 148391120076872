import { useEffect, useState } from 'react';
import Navbar from "../../Components/Home/Navbar";
import "../../Styles/ProfilePage.css";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Assuming you're using FontAwesome for the icons
import Loading from "../../Components/Home/Loading";
import axios from "axios";
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
const SettingsPage = () => {
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);
  const [showInfo, setShowInfo] = useState(true);
  const [selectedSection, setSelectedSection] = useState("show-info");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = localStorage.getItem("token"); // Retrieve token from local storage
  const [userData, setUserData] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [orders, setOrders] = useState([]);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);


  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User not authenticated. Please log in.");
      return;
    }

    try {
      const response = await axios.get("https://bk.idymorocco.com/api/orders", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && Array.isArray(response.data)) {
        setOrders(response.data);
      } else {
        setError("Failed to fetch orders.");
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
      setError("An error occurred while fetching the orders.");
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'pending':
        return 'text-yellow-500';
      case 'processing':
        return 'text-blue-500';
      case 'shipped':
        return 'text-orange-500';
      case 'delivered':
        return 'text-green-500';
      case 'returned':
        return 'text-red-500';
      case 'canceled':
        return 'text-gray-500';
      default:
        return '';
    }
  };


  useEffect(() => {
    console.log("useEffect running...");
    const storedUserData = localStorage.getItem("account_data");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData)); // Parse and set user data
    }
    const userData = localStorage.getItem('account_data');
    console.log("User Data from localStorage:", userData);

    if (userData) {
      try {
        const parsedUser = JSON.parse(userData);
        console.log("Parsed User Data:", parsedUser);
        setUser(parsedUser);
        console.log(token);
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    } else {
      console.warn("No user data found in localStorage.");
    }
  }, []);

  const handleSectionClick = (section) => {
    setSelectedSection(section);
    setShowInfo(section === "show-info");
  };

  const handleLogout = () => {
    // Clear user data from local storage
    localStorage.removeItem('token');
    localStorage.removeItem('account_data');
  
    // Show SweetAlert2 toast for successful logout
    Swal.fire({
      toast: true,
      position: 'top-end', // This places it on the right side at the top
      icon: 'success',
      title: 'You have successfully logged out',
      showConfirmButton: false,
      timer: 4000, // 3 seconds
      timerProgressBar: true,
    });
  
    // Redirect to login page or homepage after a slight delay
    setTimeout(() => {
      window.location.href = '/login'; // Change this to your desired route
    }, 1000); // Delay to ensure the toast message shows before the redirect
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    // Validate that newPassword and confirmPassword match
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    // Ensure user data is available before proceeding
    if (!userData) {
      alert("No user data available.");
      return;
    }

    // Payload using values from local storage and form inputs
    const payload = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      phone: userData.phone,
      address: userData.address,
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    };

    try {
      const response = await fetch("https://bk.idymorocco.com/api/change-information", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass token for authorization
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        alert("Password changed successfully!");
        // Optionally, reset form fields here
       
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        alert(`Error: ${data.message || "Password change failed"}`);
      }
    } catch (error) {
      console.error("Error changing password:", error);
      alert("An error occurred while changing the password.");
    }
  };

  if (!user) {
    return <Loading />
  }
  

  return (
    <>
  
      <Navbar />
      <div className="mx-4 max-w-screen-xl min-h-screen sm:mx-8 xl:mx-auto overflow-y-auto">
        <div className="grid grid-cols-8 gap-4 pt-3 sm:grid-cols-10 h-full">

          <div className="col-span-2 hidden sm:block border-r-5 h-fit">
            <ul>
              <li
                className={`mt-5 cursor-pointer border-l-2 px-2 py-2 font-hubballi transition ${
                  selectedSection === "show-info"
                    ? "border-l-[#ccb898] text-[#ccb898]"
                    : "border-transparent text-black"
                }`}
                onClick={() => handleSectionClick("show-info")}
              >
                My Information
              </li>
              <li
                className={`mt-5 cursor-pointer border-l-2 px-2 py-2 font-hubballi transition ${
                  selectedSection === "change-password"
                    ? "border-l-[#ccb898] text-[#ccb898]"
                    : "border-transparent text-black"
                }`}
                onClick={() => handleSectionClick("change-password")}
              >
                Change Password
              </li>
              <li
                className={`mt-5 cursor-pointer border-l-2 px-2 py-2 font-hubballi transition ${
                  selectedSection === "tracking"
                    ? "border-l-[#ccb898] text-[#ccb898]"
                    : "border-transparent text-black"
                }`}
                onClick={() => handleSectionClick("tracking")}
              >
                Tracking
              </li>
              <li
                className={`mt-5 cursor-pointer border-l-2 px-2 py-2 font-hubballi transition ${
                  selectedSection === "logout"
                    ? "border-l-[#ccb898] text-[#ccb898]"
                    : "border-transparent text-black"
                }`}
                onClick={() => {
                  handleLogout();
                  setSelectedSection("logout"); // Optionally update section if needed
                }}
              >
                Log Out
              </li>
            </ul>
          </div>

          <div className="col-span-8 rounded-xl border sm:px-8 h-full font-hubballi">
            <div className="pt-4">
              <h1 className="py-2 text-2xl font-hubballi">My account</h1>
            </div>
            <hr className="mt-4 mb-8" />
            {/* Change Password Section */}
            {selectedSection === "change-password" && (
              <form onSubmit={handlePasswordChange} className="space-y-6">
    <div className="relative">
      <label htmlFor="currentPassword" className="block text-sm text-gray-700 mb-2">
        Current Password *
      </label>
      <input
        type={showPassword ? "text" : "password"} // Toggle between text and password
        id="currentPassword"
        name="currentPassword"
        className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
        required
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />
      <span
        onClick={togglePasswordVisibility}
        className="absolute right-3 bottom-4 cursor-pointer"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Toggle icons */}
      </span>
    </div>

    <div className="relative">
      <label htmlFor="newPassword" className="block text-sm text-gray-700 mb-2">
        New Password *
      </label>
      <input
        type={showNewPassword ? "text" : "password"} // Toggle between text and password
        id="newPassword"
        name="newPassword"
        className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
        required
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <span
        onClick={toggleNewPasswordVisibility}
        className="absolute right-3 bottom-4 cursor-pointer"
      >
        {showNewPassword ? <FaEyeSlash /> : <FaEye />} {/* Toggle icons */}
      </span>
    </div>

    <div className="relative font-hubballi">
      <label htmlFor="confirmPassword" className="block text-sm text-gray-700 mb-2">
        Confirm New Password *
      </label>
      <input
        type={showConfirmPassword ? "text" : "password"} // Toggle between text and password
        id="confirmPassword"
        name="confirmPassword"
        className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
        required
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <span
        onClick={toggleConfirmPasswordVisibility}
        className="absolute right-3 bottom-4 cursor-pointer"
      >
        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />} {/* Toggle icons */}
      </span>
    </div>

    <div className="flex justify-between">
      <button type="submit" className="bg-black text-white p-3">
        Apply Changes
      </button>
    </div>
  </form>
            )}

            {selectedSection === "show-info" && showInfo && user && (
              <div className="pt-4">
                <div className="max-w-3xl mx-auto p-4 bg-white font-hubballi">
                  <div className="flex flex-col sm:flex-row sm:space-x-6 ">
                    <div className="flex-1">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        First Name
                      </label>
                      <div
                        id="first-name"
                      >
                        {user.first_name}
                      </div>
                    </div>
                    <div className="flex-1">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Last Name
                      </label>
                      <div
                        id="last-name"
                      >
                        {user.last_name}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row sm:space-x-6 mt-4">
                    <div className="flex-1">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Email
                      </label>
                      <div
                        id="email"
                      >
                        {user.email}
                      </div>
                    </div>
                    <div className="flex-1">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Phone
                      </label>
                      <div
                        id="phone"
                      >
                        {user.phone}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row sm:space-x-6 mt-4">
                    <div className="flex-1">
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Address
                      </label>
                      <div
                        id="address"
                      >
                        {user.address}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Tracking Section */}
            {selectedSection === "tracking" && (
              <div className="pt-4">
                <h1 className="text-2xl font-hubballi text-center">Order Tracking</h1>
                <p className="mt-2 text-center">Here you can track your orders and view their status.</p>
                <div className="container mx-auto px-4 md:px-8 lg:px-32 py-8">
        {error && <p className="text-red-500 text-center">{error}</p>}
        {orders.length > 0 ? (
          <div>
            {orders.map((order) => (
              <div key={order.id} className="border p-4 mb-4">
                <h2 className="text-xl font-hubballi">Order ID: {order.id}</h2>
                <p><strong>Name:</strong> {order.Name}</p>
                <p><strong>Company:</strong> {order.company}</p>
                <p><strong>Shipping Address:</strong> {order.Shipping_Address}</p>
                <p><strong>Postal Code:</strong> {order.postal_code}</p>
                <p><strong>City:</strong> {order.city}</p>
                <p><strong>Phone:</strong> {order.phone}</p>
                <p><strong>Total:</strong> ${order.total}</p>
                <p className={getStatusStyle(order.status)}>
                  <strong>Status:</strong> {order.status}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p className="mt-4 text-center bg-gray-500 text-white p-2 rounded">No orders found.</p>
        )}
      </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;