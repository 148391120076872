import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Styles/Loginpage.css"; // Adjust the path as necessary
import Header from "../../Components/Home/Header"; // Adjust the path as necessary
import Navbar from "../../Components/Home/Navbar"; // Adjust the path as necessary
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing eye icons
import Swal from "sweetalert2"; // Import SweetAlert2

const LoginPage = () => {
  const [email, setEmail] = useState(""); // State for email
  const [password, setPassword] = useState(""); // State for password
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const navigate = useNavigate(); // Get the navigate function for redirecting

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("token");
      localStorage.removeItem("account_data");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch("https://bk.idymorocco.com/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email, // Pass the email from state
          password: password, // Pass the password from state
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to log in: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Login response:", data);

      // Store the token and account data in localStorage
      localStorage.setItem("token", data.access_token);
      localStorage.setItem("account_data", JSON.stringify(data.Account_data[0]));

    
        navigate("/");
    

    } catch (error) {
      console.error("Login failed", error);
      setErrorMessage("Check your email & password. and try again.");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="login-container">
        <h1 className="login-title">LOGIN</h1>
        <p className="login-subtitle">Please enter your e-mail and password:</p>
        <form className="login-form" onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            className="login-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"} // Toggle input type based on state
              placeholder="Password"
              className="login-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Eye icon based on visibility */}
            </button>
            <a href="#forgot-password" className="forgot-password">
              Forgot password?
            </a>
          </div>
          {errorMessage && <p className="error-message text-red-600 mt-4">{errorMessage}</p>}
          <button type="submit" className="login-button">
            LOGIN
          </button>
        </form>
        <p className="create-account">
          Don't have an account? <Link to="/register">Create one</Link>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
