import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Home/Navbar"; // Adjust the path as necessary
import visa from "./../../Assets/Icons payement/visa2.png";
import card from "./../../Assets/Icons payement/pngegg (8).png";
import americanExpress from "./../../Assets/Icons payement/americanexpress.jpeg";

import Payment from "./Payment";
import CashondeliveryForm from "./Cashondelivery";
import { FaTrash } from "react-icons/fa"; // Import trash icon

import axios from "axios";

const Checkout = ({ currency }) => {
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0); // Add state for total weight
  const [error, setError] = useState("");
  const [couponCode, setCouponCode] = useState(""); // Add state for coupon code
  const [discount, setDiscount] = useState(0); // Add state for discount
  const [isCouponApplied, setIsCouponApplied] = useState(false); // Add state for coupon application
  const [newTotalAmount, setNewTotalAmount] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(0); // Initialize shipping price to 0
  const [selectedShippingRate, setSelectedShippingRate] = useState(null); // Add state for selected shipping rate

  const [shippingZones, setShippingZones] = useState([]); // Add state for shipping zones
  const [unavailableProducts, setUnavailableProducts] = useState([]); // Add state for unavailable products
  const [selectedCountry, setSelectedCountry] = useState(""); // Add state for selected country
  const [selectedCity, setSelectedCity] = useState(""); // Add state for selected city
  const [paymentMethod, setPaymentMethod] = useState("credit_card"); // Add state for payment method
  const [selectedOptions, setSelectedOptions] = useState({}); // Initialize selectedOptions as an empty object
  const [exchangeRate, setExchangeRate] = useState(1); // Add state for exchange rate

  useEffect(() => {
    // Fetch cart items from the API
    const fetchCartItems = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError(" not authenticated. Please log in.");
        return;
      }

      try {
        const response = await axios.get(
          `https://bk.idymorocco.com/api/carts?currency=${currency}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Full response:", response);

        if (response.data && response.data.data) {
          const items = response.data.data;
          setCartItems(items);
          const storeIds = items.map(item => item.product.store_id);
          localStorage.setItem("store_ids", JSON.stringify(storeIds));
        } 
      } catch (error) {
        setError("An error occurred while fetching cart items.");
      }
    };

    fetchCartItems();
  }, [currency]);

  useEffect(() => {
    // Fetch shipping zones from the API
    const fetchShippingZones = async () => {
      try {
        const response = await axios.get("https://bk.idymorocco.com/api/shipping-zones");
        setShippingZones(response.data);
        console.log('dazefzfezf',response)
      } catch (error) {
        console.error("Error fetching shipping zones:", error);
      }
    };

    fetchShippingZones();
  }, []);

  useEffect(() => {
    // Calculate the total weight of the cart items
    const totalWeight = cartItems.reduce((sum, item) => {
      console.log(`Processing item: ${JSON.stringify(item)}`); // Log the entire item for debugging
      if (item.variant) {
        const variantOption = item.variant.options.find(option => option.id === item.variant_id);
        if (variantOption) {
          console.log(`Product ID: ${item.product_id}, Variant Option:`, variantOption);
          if (item.variant.variant_name === "weight") {
            const weight = parseFloat(variantOption.option_value.replace("kg", ""));
            console.log(`Product ID: ${item.product_id}, Weight: ${weight}kg`); // Log the weight of each product
            return sum + (weight * item.quantity_ordered);
          }
        }
      }
      return sum;
    }, 1);
    console.log(`Total Weight: ${totalWeight}kg`);
    setTotalWeight(totalWeight);
  }, [cartItems]);

  const fetchExchangeRate = async (toCurrency) => {
    try {
      const apiUrl = selectedCountry.toLowerCase() === "morocco" || selectedCountry.toLowerCase() === "maroc" 
        ? `https://api.exchangerate-api.com/v4/latest/mad` 
        : `https://api.exchangerate-api.com/v4/latest/usd`;
      const response = await axios.get(apiUrl);
      const data = response.data;
      console.log('to', data.rates[toCurrency.toUpperCase()]);
      return data.rates[toCurrency.toUpperCase()];
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
    }
  };
  
  const calculateShippingRate = (country, weight) => {
    let rate;
    if (country.toLowerCase() === "morocco" || country.toLowerCase() === "maroc") {
      if(weight == 0){
        rate= 20.00
      }
      else if (weight >= 1 && weight <= 5) {
        rate = 20.00;
      } else if (weight >= 6 && weight <= 10) {
        rate = 50.00;
      } else if (weight >= 11 && weight <= 29) {
        rate = 150.00;
      } 
    } else {
      switch (weight) {
        case 1:
          rate = 40;
          break;
        case 2:
          rate = 50;
          break;
        case 3:
          rate = 60;
          break;
        case 4:
          rate = 70;
          break;
        case 5:
          rate = 80;
          break;
        case 6:
          rate = 90;
          break;
        case 7:
          rate = 100;
          break;
        default:
          rate =0
      }
    }
    return rate;
  };
  
  useEffect(() => {
  
    const updateShippingRate = async () => {
      const rate = await fetchExchangeRate(currency);
      setExchangeRate(rate);
      const shippingRate = calculateShippingRate(selectedCountry, totalWeight) * rate;
      setShippingPrice(shippingRate);
    };
  
    updateShippingRate();
  }, [totalWeight, selectedCountry, currency]);
  
  useEffect(() => {
    // Calculate the total amount including the shipping price
    const totalWithShipping = totalAmount + shippingPrice;
    setNewTotalAmount(totalWithShipping);
  }, [totalAmount, shippingPrice]);

  const handleApplyCoupon = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User not authenticated. Please log in.");
      return;
    }

    try {
      const response = await axios.post(
        "https://bk.idymorocco.com/api/apply-coupon",
        {
          coupon_code: couponCode,
          cart_total: newTotalAmount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        const discountPercentage = response.data.discount;
        const discountAmount = totalAmount * (discountPercentage / 100);
        const newTotal = totalAmount - discountAmount;

        setDiscount(discountPercentage);
        setNewTotalAmount(newTotal + shippingPrice); 
        setIsCouponApplied(true);
        setCouponCode("");
        setError("");
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("Failed to apply coupon. Please try again.");
    }
  };

  const handleRemoveCoupon = () => {
    setDiscount(0);
    setNewTotalAmount(totalAmount + shippingPrice); 
    setIsCouponApplied(false);
  };

  const handlePaymentSuccess = () => {
    // Clear cart items and total amount
    setCartItems([]);
    setTotalAmount(0);
    setDiscount(0);
    setIsCouponApplied(false); // Reset coupon application state
    setNewTotalAmount(0); // Clear newTotalAmount
    console.log("Payment successful. Cart cleared.");
  };
  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setSelectedCity(selectedCity);
    const storedRates = JSON.parse(localStorage.getItem("shipping_rates"));
    if (storedRates && storedRates.length > 0) {
      const selectedRate = storedRates.find(rate => rate.rate_name === selectedCity);
      setSelectedShippingRate(selectedRate); 
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setSelectedCountry(selectedCountry);
    setSelectedCity(""); // Reset city when country changes
    const storeIds = JSON.parse(localStorage.getItem("store_ids")) || [];
    const selectedCountryZones = shippingZones.filter(
      (zone) => zone.country === selectedCountry && storeIds.includes(zone.store_id)
    );

    const rates = selectedCountryZones.flatMap(zone => zone.shipping_rates || []);
    localStorage.setItem("shipping_rates", JSON.stringify(rates));

    // Check for unavailable products
    const unavailable = storeIds.filter(
      storeId => !selectedCountryZones.some(zone => zone.store_id === storeId)
    );
    setUnavailableProducts(unavailable);
  };

  useEffect(() => {
    // Calculate the total amount excluding unavailable products
    const availableItems = cartItems.filter(
      item => !unavailableProducts.includes(item.product.store_id)
    );
    const availableTotal = availableItems.reduce(
      (sum, item) => sum + item.price,
      0
    );
    setTotalAmount(availableTotal);
  }, [cartItems, unavailableProducts]);
        
  const deleteCartItem = async (id) => {
    // Add this function
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User not authenticated. Please log in.");
      return;
    }
  
    try {
      const response = await axios.delete(
        `https://bk.idymorocco.com/api/carts/remove/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log("Delete response:", response); 
  
      if (response.status === 200) {
       
    
      } else {
        setError("Failed to delete cart item.");
      }
    } catch (error) {
      console.error("Error deleting cart item:", error);
      setError("An error occurred while deleting the cart item.");
    }
  };
  return (
    <div>
      <Navbar />
      <div className="container mx-auto p-6 lg:pl-32 lg:pr-32 font-hubballi">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Section: Contact, Delivery, and Payment Form */}
         
          <div >
          <div className="my-8">
            {/* Payment Icons */}
            <div className="flex space-x-6">
              <img
                src={card}
                alt="Visa"
                className="w-20 h-auto sm:w-20 md:w-28 shadow-[2.0px_2.0px_8.0px_rgba(0,0,0,0.38)] rounded px-2 sm:px-4"
              />
              <img
                src={visa}
                alt="Mastercard"
                className="w-20 h-auto sm:w-20 md:w-28 shadow-[2.0px_2.0px_8.0px_rgba(0,0,0,0.38)] rounded px-2 sm:px-4"
              />
              <img
                src={americanExpress}
                alt="Mastercard"
                className="w-20 h-auto sm:w-20 md:w-28 shadow-[2.0px_2.0px_8.0px_rgba(0,0,0,0.38)] rounded px-2 sm:px-4"
              />
               </div>
            </div>
            {/* Payment Method Selection */}
            <div className="flex space-x-4 ">
              <label className={`flex items-center ${paymentMethod === "credit_card" ? "bg-white border p-3 " : ""}`}>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="credit_card"
                  checked={paymentMethod === "credit_card"}
                  onChange={() => setPaymentMethod("credit_card")}
                  className="mr-2 custom-checkbox" 
                />
                Credit Card
              </label>
              {selectedCountry.toLowerCase() === "maroc" && (
                <label className={`flex items-center ${paymentMethod === "cash_on_delivery" ? "border bg-white   p-3 " : ""}`}>
                  <input
                    type="radio"
                  name="paymentMethod"
                  value="cash_on_delivery"
                  checked={paymentMethod === "cash_on_delivery"}
                  onChange={() => setPaymentMethod("cash_on_delivery")}
                  className="mr-2"
                />
                  Cash on Delivery
                </label>
              )}
            </div>
            {/* Coupon Code Input */}
            {paymentMethod === "credit_card" && (
              <Payment
                currency={currency}
                onSuccess={handlePaymentSuccess}
                couponCode={couponCode}
                onCityChange={handleCityChange} 
                setSelectedShippingRate={setSelectedShippingRate} 
                onCountryChange={handleCountryChange} 
                selectedCountry={selectedCountry} 
                selectedCity={selectedCity} 
                totalAmount={newTotalAmount} 
                
              />
            )}
            {paymentMethod === "cash_on_delivery" && (
              <CashondeliveryForm
                currency={currency}
                onSuccess={handlePaymentSuccess}
                couponCode={couponCode}
                onCityChange={handleCityChange} 
                setSelectedShippingRate={setSelectedShippingRate} 
                onCountryChange={handleCountryChange}
                selectedCountry={selectedCountry} 
                selectedCity={selectedCity} 
                totalAmount={newTotalAmount} 
              />
            )}
          </div>

          {/* Right Section: Order Summary */}
          <div className="border p-6 rounded">
            <h4 className="text-lg font-semibold mb-4">Order Summary</h4>
            {error && <p className="text-red-500">{error}</p>}
            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <div key={item.id} className="flex justify-between mb-4">
                  <div className="flex items-center space-x-4">
                 
  <div className="relative">
    <img
      src={
        item.product.images && item.product.images.length > 0
          ? item.product.images[0].url
          : `https://via.placeholder.com/300?text=${item.product.name}`
      }
      alt={`Product ${item.product_id}`}
      className="w-20 h-20 object-cover border border-gray-300"
    />
    <div className="absolute bottom-16 left-16  bg-gray-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
      {item.quantity_ordered}
    </div>
  </div>
  <div>
    {/* Other content */}
  </div>

                    <div>
                      <span className="text-sm uppercase mb-1">
                        {item.product.name}
                      </span>
                      {item.product.variants && item.product.variants.map((variant) =>
                        variant.variant_name === "weight" &&
                        variant.options.map((option) =>
                          selectedOptions[variant.variant_name]?.includes(option.id) ? (
                            <div key={option.id} className="text-sm text-gray-600">
                              {option.option_value}
                            </div>
                          ) : null
                        )
                      )}
                    </div>
                    {unavailableProducts.includes(item.product.store_id) && (
                      <>
                      <span className="text-red-500 text-sm">
                        This product is not available in your selected country.
                      </span>
                       <button onClick={() => deleteCartItem(item.id)} className="  text-red-600 rounded-full p-1">
                       <FaTrash />
                     </button>
                     </>
                    )}
                </div>
                  <span className="text-sm text-gray-600 text-right mt-7">
                    {item.price.toFixed(2)} {currency.toUpperCase() ? currency.toUpperCase() : "MAD"}
                  </span>
                  {unavailableProducts.includes(item.product.store_id) && (
                      <>
                     
                       {/* <button onClick={() => deleteCartItem(item.id)} className=" space-x-4 text-red rounded-full p-1">
                       <FaTrash />
                     </button> */}
                     </>
                    )}
                </div>
              ))
            ) : (
              <p>No items in the cart.</p>
            )}

            {!isCouponApplied ? (
              <>
                <label
                  htmlFor="couponCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Coupon Code
                </label>
                <div className="flex justify-between mb-4 space-x-3">
                  <input
                    type="text"
                    id="couponCode"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    className="w-full p-4 border border-gray-300 text-sm hover:border-[#ccb898]"
                  />
                  <button
                    onClick={handleApplyCoupon}
                    className="mt-1 bg-black text-white py-2 px-4 rounded-md"
                    disabled={unavailableProducts.length > 0}
                  >
                    Apply
                  </button>
                  
                </div>
              </>
            ) : (
              <div className="flex justify-between items-center mb-4">
                <p className="text-sm text-green-600">
                  Discount of {discount}% applied.
                </p>
                <FaTrash
                  className="text-red-500 cursor-pointer"
                  onClick={handleRemoveCoupon}
                />
              </div>
            )}

            <div className="mt-4">
              <div className="flex justify-between mb-2">
                <span className="text-sm">Subtotal ({cartItems.length} items)</span>
                <p>
                  {totalAmount.toFixed(2)} {currency.toUpperCase()?currency.toUpperCase():'MAD'}
                </p>
              </div>
              {selectedCity && (
                <div className="flex justify-between mb-2">
                  <span className="text-sm">Shipping Rate</span>
                  <p>{shippingPrice.toFixed(2)} {currency.toUpperCase()?currency.toUpperCase():'MAD'}</p>
                </div>
              )}
              {selectedCity && (
                <div className="flex justify-between mb-2">
                  <h1 className="">Total</h1>
                  <p>
                    {newTotalAmount.toFixed(2)}{" "}
                    {currency.toUpperCase()?currency.toUpperCase():'MAD'}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;