import React from 'react';
import Navbar from "../../Components/Home/Navbar";
const ContactUs = () => {
    return (
        <><Navbar /><div className="container mx-auto p-6 font-hubballi">
            <h1 className="text-3xl font-bold mb-6 text-center">Contactez-nous</h1>

            <div className="bg-white p-6">
                <h2 className="text-2xl font-semibold mb-4">Nos coordonnées</h2>

                <p className="mb-2">
                    <strong>Adresse :</strong> Rue Mouslim, lot Boukar, Bab Doukala, Marrakech - Maroc
                </p>
                <p className="mb-2">
                    <strong>Téléphone :</strong> +212 664 514 743
                </p>
                <p className="mb-4">
                    <strong>Email :</strong> <a href="mailto:Contact@idymorocco.com" className="text-blue-500 hover:underline">Contact@idymorocco.com</a>
                </p>

                <h2 className="text-xl font-semibold mb-4">Nous écrire</h2>
                <form className="flex flex-col space-y-4">
                    <input
                        type="text"
                        placeholder="Votre nom"
                        className="p-2 border border-gray-300"
                        required />
                    <input
                        type="email"
                        placeholder="Votre email"
                        className="p-2 border border-gray-300"
                        required />
                    <textarea
                        placeholder="Votre message"
                        className="p-2 border border-gray-300"
                        rows="4"
                        required />
                    <button
                        type="submit"
                        className="bg-[#ccb898] text-white p-2  hover:bg-white hover:text-black border transition duration-200"
                    >
                        Envoyer
                    </button>
                </form>
            </div>
        </div></>
    );
};

export default ContactUs;
