import React, { useState, useEffect } from "react";
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51PxTOy03NgnNbD5q7mS0syD2PbLsxYRlyWpSBOMjqpNPa9PKMfMPk8QEcVUPYeXJxGyxeOKjM3cNQCuetvrcSb4b00owhTqpTi"
);

const CheckoutForm = ({
  currency,
  onSuccess,
  couponCode,
  onCityChange,
  setSelectedShippingRate,
  onCountryChange,
  selectedCountry,
  selectedCity,
  totalAmount,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    address: "",
    address2: "",
    postalCode: "",
    city: "",
    country: "",
  });
  const [shippingRates, setShippingRates] = useState([]);
  const [selectedShippingRate, setSelectedShippingRateLocal] = useState(0);
  const [total, setTotal] = useState(totalAmount);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);
  const [unavailableProducts, setUnavailableProducts] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("credit_card");
  const [exchangeRate, setExchangeRate] = useState(1);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchShippingZones = async () => {
      try {
        const response = await fetch(
          "https://bk.idymorocco.com/api/shipping-zones",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setShippingRates(data);
      } catch (error) {
        console.error("Error fetching shipping zones:", error);
      }
    };

    fetchShippingZones();
  }, [token]);

  useEffect(() => {
    const storeIds = JSON.parse(localStorage.getItem("store_ids")) || [];
    const countries = shippingRates
      .filter((zone) => storeIds.includes(zone.store_id))
      .map((zone) => zone.country);

    setAvailableCountries([...new Set(countries)]);
  }, [shippingRates]);

  useEffect(() => {
    const fetchExchangeRate = async (fromCurrency, toCurrency) => {
      try {
        const response = await fetch(`https://api.exchangerate-api.com/v4/latest/${fromCurrency}`);
        const data = await response.json();
        if (data.rates[toCurrency]) {
          setExchangeRate(data.rates[toCurrency]);
        } else {
          console.error(`Exchange rate for ${toCurrency} not found.`);
        }
      } catch (error) {
        console.error('Failed to fetch exchange rate:', error);
      }
    };

    if (currency !== 'USD') {
      fetchExchangeRate('USD', currency);
    }
  }, [currency]);

  useEffect(() => {
    const baseTotal = totalAmount;
    const convertedShippingRate = selectedShippingRate * exchangeRate;
    setTotal(baseTotal + convertedShippingRate);
    setSelectedShippingRateLocal(convertedShippingRate);
  }, [selectedShippingRate, exchangeRate, totalAmount]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "country") {
      onCountryChange(e);
      const selectedCountryZones = shippingRates.filter(
        (zone) => zone.country === value
      );
      const cities = selectedCountryZones.flatMap((zone) =>
        zone.shipping_rates.map((rate) => rate.rate_name)
      );
      setAvailableCities([...new Set(cities)]);
    }

    if (name === "city") {
      onCityChange(e);
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
  
    if (paymentMethod === "credit_card") {
      if (!stripe || !elements) {
        return;
      }
  
      const cardNumberElement = elements.getElement(CardNumberElement);
      const { error, paymentMethod: stripePaymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardNumberElement,
        });
  
      if (error) {
        console.error("Error:", error);
        alert(error.message);
        setLoading(false);
        return;
      }
  
      try {
        const response = await fetch("https://bk.idymorocco.com/api/checkout", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            payment_method_id: stripePaymentMethod.id,
            return_url: "http://localhost:3000",
            currency: currency || "usd",
            first_name: formData.firstName,
            last_name: formData.lastName,
            phone: formData.phone,
            address: formData.address,
            address2: formData.address2,
            postal_code: formData.postalCode,
            city: formData.city,
            coupon_code: couponCode,
            country: formData.country,
            total: total,
          }),
        });
  
        const text = await response.text();
        console.log("Server response:", text);
        const data = JSON.parse(text);
  
        if (data.message.toLowerCase().includes("success")) {
          alert("Payment successful!");
          setFormData({
            firstName: "",
            lastName: "",
            phone: "",
            address: "",
            address2: "",
            postalCode: "",
            city: "",
            country: "",
          });
          onSuccess();
        } else {
          alert("Payment failed: " + (data.message || "Unknown error"));
        }
      } catch (err) {
        console.error("Error parsing JSON:", err);
        alert("Error parsing server response");
      }
    }
  
    setLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-lg   rounded p-10  bg-white  border font-hubballi"
    >
      <div>
        <input
          type="email"
          className="w-full p-3 border border-gray-300 hover:border-[#ccb898] focus:outline-none"
          placeholder="Email"
        />
      </div>
      <div className="flex items-center mb-4">
        <input
          id="default-checkbox"
          type="checkbox"
          value=""
          className="w-4 h-4 custom-checkbox"
        />
        <label
          htmlFor="default-checkbox"
          className="ms-2 text-sm font-medium text-black"
        >
          Email me with news and offers
        </label>
      </div>

      <h2 className="font-bold mt-6">Delivery</h2>
      <div className="w-full grid grid-cols-1">
        <select
          id="country"
          name="country"
          value={formData.country}
          onChange={handleChange}
          className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none my-3"
        >
          <option value="" disabled>
            Country/Region
          </option>
          {availableCountries.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <input
            type="text"
            className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
          />
        </div>
        <div>
          <input
            type="text"
            className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
          />
        </div>
        <div className="col-span-2">
          <input
            type="text"
            className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
            placeholder="Company (optional)"
          />
        </div>
        <div className="col-span-2">
          <input
            type="text"
            className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="Address"
          />
        </div>
        <div className="col-span-2">
          <input
            type="text"
            className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
            name="address2"
            value={formData.address2}
            onChange={handleChange}
            placeholder="Apartment, suite, etc"
          />
        </div>
        <div>
          <input
            type="text"
            className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            placeholder="Postal Code"
          />
        </div>
        <div>
          <select
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
          >
            <option value="" disabled>
              City
            </option>
            {availableCities.map((city, index) => (
              <option key={index} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-2">
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 text-sm hover:border-[#ccb898] focus:outline-none"
            placeholder="Phone Number"
          />
        </div>
      </div>

      <div className="mb-4 my-7">
        
      <h2 className="text-xl font-semibold mb-4">Payment </h2>
            
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Card Number</label>
                <div className="mt-1">
                    <CardNumberElement  className="p-3 border border-gray-300  focus:outline-none focus:ring focus:ring-blue-300" />
                </div>
            </div>

            <div className="flex mb-4">
                <div className="mr-2 flex-1">
                    <label className="block text-sm font-medium text-gray-700">Expiration Date</label>
                    <div className="mt-1">
                        <CardExpiryElement className="p-3 border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300" />
                    </div>
                </div>
                <div className="flex-1">
                    <label className="block text-sm font-medium text-gray-700">CVC</label>
                    <div className="mt-1">
                        <CardCvcElement className="p-3 border border-gray-300 focus:outline-none focus:ring focus:ring-blue-300" />
                    </div>
                </div>
            </div>

           
      </div>

      
      <button
        type="submit"
        disabled={loading}
        className="w-full bg-black text-white py-2 px-4 text-xl shadow-sm hover:bg-white hover:text-black border-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {loading ? "Processing..." : "Pay"}
      </button>

      {unavailableProducts.length > 0 && (
        <div className="mt-4 text-red-500">
          {unavailableProducts.map((storeId, index) => (
            <p key={index}>
              Product from store {storeId} is not available in your selected
              country.
            </p>
          ))}
        </div>
      )}
    </form>
  );
};

const Payment = ({
  currency,
  onSuccess,
  couponCode,
  onCityChange,
  setSelectedShippingRate,
  onCountryChange,
  selectedCountry,
  selectedCity,
  totalAmount,
}) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm
      currency={currency}
      onSuccess={onSuccess}
      couponCode={couponCode}
      onCityChange={onCityChange}
      setSelectedShippingRate={setSelectedShippingRate}
      onCountryChange={onCountryChange}
      selectedCountry={selectedCountry}
      selectedCity={selectedCity}
      totalAmount={totalAmount}
    />
  </Elements>
);

export default Payment;