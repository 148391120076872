/* eslint-disable jsx-a11y/anchor-is-valid */
// src/Components/Footer.js
import React from "react";
import "../../Styles/Footer.css";
import visa from "../../Assets/Icons payement/visa.png";
import card from "../../Assets/Icons payement/card.png";
import logo1 from "../../Assets/logo1.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="footer bg-gray-100 p-6">
  <div className="container mx-auto">
    {/* <!-- Footer Columns --> */}
    <div className="footer-columns flex flex-col md:flex-row md:justify-between gap-8">
      {/* <!-- Information Column --> */}
      <div className="footer-column mb-8 md:mb-0">
        <h4 className="text-lg font-hubballi">INFORMATION</h4>
        <ul className="space-y- mt-2">
        <Link to="/about-us">
          <li>
          <a href="" className="text-gray-700 hover:text-[#ccb898]">About Us</a>
          </li>
          </Link>
          <Link to="/Contact-us">
          <li><a href="" className="text-gray-700 hover:text-[#ccb898]">Contact Us</a></li>
          </Link>
          <Link to="/blog"><li><a href="" className="text-gray-700 hover:text-[#ccb898]">Blog</a></li>
          </Link>
          
        </ul>
      </div>
      {/* <!-- Customer Service Column --> */}
      <div className="footer-column mb-8 md:mb-0">
        <h4 className="text-lg font-hubballi">CUSTOMER SERVICE</h4>
        <ul className="space-y- mt-2">
          <Link to="/Terms-Conditions"><li><a href="#terms" className="text-gray-700 hover:text-[#ccb898]">Terms & Conditions</a></li></Link>
          <Link to="/Faq"> <li><a href="#faq" className="text-gray-700 hover:text-[#ccb898]">FAQ</a></li></Link>
          <Link to="/Returns-Delivery"><li><a href="#returns" className="text-gray-700 hover:text-[#ccb898]">Returns & Delivery</a></li></Link>
          <Link to="/Privacy-Policy">  <li><a href="#privacy" className="text-gray-700 hover:text-[#ccb898]">Privacy Policy</a></li></Link>
        
          {/* <li onClick={() => window.location.href = `/login`}>
          <a href="#login" className="text-gray-700 hover:text-[#ccb898]">Log in/Register</a>
          </li> */}
        </ul>
      </div>
      {/* <!-- Follow Us Column --> */}
      <div className="footer-column">
        <h4 className="text-lg font-hubballi">FOLLOW US</h4>
        <p className="mt-2 text-black">
          For more style inspiration, follow us on <a href="#instagram" className="underline text-gray-900 hover:text-gray-600">Instagram</a> and tag us with your purchases to be featured.
        </p>
        <div class="container mx-auto flex justify-between items-center">
    {/* <!-- Social Icons --> */}
    <div class="social-icons flex space-x-4">
      <a href="#facebook" class="text-gray-500 hover:text-[#ccb898]">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="#instagram" class="text-gray-500 hover:text-[#ccb898]">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="#pinterest" class="text-gray-500 hover:text-[#ccb898]">
        <i class="fab fa-pinterest"></i>
      </a>
    </div>

    {/* <!-- Logo --> */}
    <div>
      <img class="w-20 h-auto rotate-3" src={logo1} alt="logo" />
    </div>
  </div>
      </div>
    </div>

    {/* <!-- Footer Bottom Section with Logo --> */}
    <div className="mt-6 flex flex-col md:flex-row justify-between items-center">
      <span className="text-gray-500 text-sm mb-4 md:mb-0">&copy; IDY MOROCO</span>
      <div className="flex space-x-4">
        <img src={visa} alt="Visa" className="h-10" />
        <img src={card} alt="Mastercard" className="h-10" />
      </div>
    </div>
  </div>
</footer>




  );
};

export default Footer;
