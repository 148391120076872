import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import "../../Styles/Registerpage.css"; // Adjust the path as necessary
import Header from "../../Components/Home/Header"; // Adjust the path as necessary
import Navbar from "../../Components/Home/Navbar"; // Adjust the path as necessary
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import Swal from 'sweetalert2'; // Import SweetAlert2

const RegisterPage = () => {
  // State management for form inputs
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState(""); // Added gender state
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false); // State for confirm password visibility
  const navigate = useNavigate(); // For redirection

  // Handle form submission
  const handleRegister = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch("https://bk.idymorocco.com/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          password_confirmation: passwordConfirmation,
          phone: phone,
          address: address,
          gender: gender, // Send gender in the request
        }),
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error(`Failed to register: ${response.status} ${response.statusText}`);
      }

      // Parse the response JSON
      const data = await response.json();
      console.log("Registration successful", data);

      // Show alert on successful registration
      Swal.fire({
        title: 'Registration Successful!',
        text: 'You can now log in.',
        icon: 'success',
        confirmButtonText: 'OK',
        // You can customize the styles if needed
        customClass: {
          title: 'swal-title', // Add custom classes for further styling
        },
      });

      // Optionally store the access token for later use (e.g., in localStorage)
      localStorage.setItem("access_token", data.access_token);

      // Redirect to the login page upon successful registration
      navigate("/login");

    } catch (error) {
      console.error("Registration failed", error);
      setErrorMessage("Registration failed. Please check your input and try again.");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="register-container">
        <h1 className="register-title">REGISTER</h1>
        <p className="register-subtitle">Please fill in the information below:</p>
        <form className="register-form" onSubmit={handleRegister}>
          <input
            type="text"
            placeholder="First Name"
            className="register-input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Last Name"
            className="register-input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <select
            className="register-input"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          <input
            type="email"
            placeholder="Email"
            className="register-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="password-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="register-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="password-container">
            <input
              type={showPasswordConfirmation ? "text" : "password"}
              placeholder="Confirm Password"
              className="register-input"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
            >
              {showPasswordConfirmation ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <input
            type="text"
            placeholder="Phone"
            className="register-input"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Address"
            className="register-input"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          {errorMessage && <p className="error-message text-red-600">{errorMessage}</p>}
          <button type="submit" className="register-button">
            CREATE MY ACCOUNT
          </button>
        </form>
        <p className="login-account">
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  );
};

export default RegisterPage;
