import React, { useState } from 'react';
import "../../Styles/Header.css";
import ReactFlagsSelect from 'react-flags-select';

const Header = ({ onCurrencyChange }) => {
  const [selectedCountry, setSelectedCountry] = useState("MA"); // Default to Morocco
  const [language, setLanguage] = useState("US");

  const currencyMap = {
    "MA": "MAD",
    "US": "USD",
    "EU": "EUR",
    "GB": "GBP",
    "AU": "AUD",
    "CA": "CAD",
    "AE": "AED",
    "BH": "BHD",
    "EG": "EGP",
    "KW": "KWD",
    "IL": "ILS",
    "QA": "QAR",
    "SA": "SAR",
    "JP": "JPY",
    "CN": "CNY"
  };

  const handleCurrencyChange = (code) => {
    const selectedCurrency = currencyMap[code];
    setSelectedCountry(code); // Update selected country dynamically
    onCurrencyChange(selectedCurrency); // Notify parent component
  };

  const handleLanguageChange = (code) => {
    setLanguage(code); // Update language state
  };

  return (
    <div className="header-container">
      <div className="header-content">
        <h1 className="header-title">
          10% OFF YOUR FIRST ORDER USING CODE: IDY MOROCCO
        </h1>
      </div>
      <div className="selectors-container">
        <div className="currency-selector ">
          <ReactFlagsSelect
            countries={["MA", "US","FR", "EU", "GB", "AU", "CA", "AE", "BH", "EG", "KW", "IL", "QA", "SA", "JP", "CN"]}
            customLabels={{
              "MA": "MAD",
              "FR":"EUR",
              "US": "USD",
              "EU": "EUR",
              "GB": "GBP",
              "AU": "AUD",
              "CA": "CAD",
              "AE": "AED",
              "BH": "BHD",
              "EG": "EGP",
              "KW": "KWD",
              "IL": "ILS",
              "QA": "QAR",
              "SA": "SAR",
              "JP": "JPY",
              "CN": "CNY"
            }}
            selected={selectedCountry} // Bind selected to selectedCountry state
            onSelect={handleCurrencyChange} // Update on select
            placeholder="Select Currency"
            searchable
            searchPlaceholder="Search currencies"
          />
        </div>
        <div className="language-selector relative">
          <ReactFlagsSelect
            countries={["US", "FR", "SA"]}
            customLabels={{
              "US": "EN",
              "FR": "FR",
              "SA": "AR"
            }}
            selected={language} // Bind selected to language state
            onSelect={handleLanguageChange} // Update on select
            placeholder="Select Language"
            searchable
            searchPlaceholder="Search languages"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
