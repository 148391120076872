
import React from "react";
import rugImage from './../../Assets/12edfe02-8a62-4f36-bbda-d1c33675bb3b.jpeg.jpg';
import "../../Styles/Description.css";
import "../../Styles/Blogpage.css"; // Adjust the path to your CSS file
import ahwach from "../../Assets/ahwach.png";
import product from "../../Assets/Product.png";
import kasbah from "../../Assets/kasbah.jpg";
import { Link } from "react-router-dom";
const BlogHome = () => {
  return (
    
    <>
   
    <div className=" min-h-screen flex items-center justify-center m-10 ">
      <div className=" bloghome container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-14">
          <div className="relative">
            <img
              src={rugImage}
              alt="Moroccan lamps"
              className="w-[600px] h-auto" />
          </div>
          <div className="flex flex-col justify-center space-y-20">
          <h1 className="text-3xl m-4 text-center text-customBlack tracking-wider font-hubballi uppercase">
    Terroir & Bien être
    </h1>
            <p className="text-customBlack text-2xl tracking-wider leading-relaxed leading-20	 font-hubballi">
              Illuminate your surroundings with the enchanting glow of
              our Moroccan lamps collection. From intricate metalwork to
              vibrant glass, each lamp tells a story of tradition and art.
              Discover the perfect accent to add warmth and character to
              your home.
            </p>
            <div className="flex justify-end mt-60">
            <Link to="/blog">
              <button className="bg-black py-2 px-6 hover:bg-white hover:text-black text-white  border border-gray-400 rounded-md shadow font-hubballi">
                Shop Our Products
              </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1 className=" text-center text-2xl mt-2">THE ARTISANS JOURNAL
</h1>
    <div className="blog-article-small-container relative max-w-xs overflow-hidden bg-cover bg-no-repeat">
        <div className="blog-article-small ">
          <img
            src={ahwach}
            alt="BERBER ‘AHWACH"
            className="blog-image-small max-w-xs transition duration-300 ease-in-out hover:scale-110" />
          <div className="blog-article-content">
            <h2>BERBER 'AHWACH'</h2>
            <p>
            The Amazigh, often referred to as the 'free 
            people', are the indigenous inhabitants of 
a vast region in North Africa, boasting a 
history and culture that are both deeply 
ancient and profoundly rich..
            </p>
            <a href="#read-more" className="read-more">
              Read more
            </a>
          </div>
        </div>
        <div className="blog-article-small">
  <img src={product} alt="Crafts" className="blog-image-small w-50" />
  <div className="blog-article-content">
    <h2>5 SIMPLE WAYS TO COZY UP YOUR HOME FOR AUTUMN</h2>
    <p>
      As autumn approaches, many of us seek to transform our homes into warm and inviting spaces.........
    </p>
    <a href="#read-more" className="read-more">Read more</a>
  </div>
</div>

        <div className="blog-article-small">
          <img src={kasbah} alt="Kasbah" className="blog-image-small" />
          <div className="blog-article-content">
            <h2>BERBER 'AHWACH'</h2>
            <p>
              The Amazigh, often referred to as the 'free 
              people', are the indigenous inhabitants....
            </p>
            <a href="#read-more" className="read-more">
              Read more
            </a>
          </div>
        </div>
      </div>
      <div className="  flex items-center justify-center mt-8">  
      <Link to="/blog">
        <button class=" bg-customBlack  text-white hover:bg-white hover:text-black py-2 mt-6 px-6 rounded-md font-hubballi border border-gray-400">
                VIEW ALL ARTICALES
        </button>
      </Link>
      </div>
      <div className="bg-[#ccb898] py-8 flex justify-center items-center run mx-4 sm:mx-8 mt-8">
  <div className="max-w-4xl text-center">
    <h2 className="text-2xl sm:text-3xl font-semibold mb-6 sm:mb-8 font-hubballi">JOIN OUR TRIBE</h2>
    <p className="text-lg sm:text-xl mb-4 sm:mb-6 font-hubballi text-customBlack tracking-wide sm:tracking-widest">
      Sign up to receive our emails and be the first to know about our new 
      products and exclusive offers. We promise we won’t spam you!
    </p>
    <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
    <input
  type="email"
  placeholder="Enter your email"
  className="border bg-[#ccb898] border-black px-4 py-2 rounded-md w-full max-w-xs sm:max-w-md placeholder-black font-hubballi focus:border-black focus:outline-none"
/>

      <button className="text-white py-2 px-6 bg-black hover:bg-white hover:text-black border border-black rounded-md font-hubballi uppercase  sm:w-auto">
        Subscribe
      </button>
    </div>
  </div>
</div>


      </>
  );
};
export default BlogHome;