// src/components/AboutUs.js
import React from "react";
import "../../Styles/Aboutuspage.css"; // Adjust the path as necessary
import Header from "../../Components/Home/Header"; // Adjust the path as necessary
import Navbar from "../../Components/Home/Navbar"; // Adjust the path as necessary
import Product from "../../Assets/Product.png";
import ahwach from "../../Assets/ahwach.png";

const AboutUs = () => {
  return (
    <div>
  
      <Navbar />
      <div className="aboutus-container">
        <div className="aboutus-section">
          <h2>WHO WE ARE</h2>
          <p>
            Idy Morocco connects the vibrant essence of Moroccan culture with
            the Western world. Our souq offers a thoughtfully curated selection
            of soulful, sustainably crafted home décor. By partnering directly
            with local communities, we enable Moroccan artisans to share their
            beautiful creations globally. Join us in celebrating and preserving
            Morocco's rich heritage.
          </p>
          <img src={Product} alt="WHO WE ARE" />
        </div>
        <div className="aboutus-section">
          <h2>OUR PHILOSOPHY</h2>
          <p>
            At Idy Morocco, we prioritize our planet and are dedicated to
            ensuring a sustainable product lifecycle. From the selection of raw
            materials to the careful journey each item takes from the hands of
            our artisans to your home, we thoughtfully collaborate with our
            makers at every step. We uphold fair trade principles, advocating
            for fair prices and improved working conditions for our artisan
            partners. Each hand-knotted, woven, forged, and painted piece we
            offer is a testament to artistry and patience. We deeply respect,
            trust, and honor our artisans, fostering meaningful relationships
            within the communities and cooperatives we support.
          </p>
          <img
            src={ahwach}
            alt="OUR PHILOSOPHY"
            className="aboutus-image-philosophy"
          />
        </div>
        <div className="aboutus-section">
          <h2>OUR STORY</h2>
          <p>
            Idy Morocco is a heartfelt homage to the Morocco of our cherished
            childhood memories. The enchantment and thrill we experienced
            wandering through the souks as children continue to inspire us. Our
            deep love and admiration for our Moroccan homeland, its rich
            culture, and its artistic legacy drive us. Idy Morocco was founded
            with a simple yet profound mission: to empower our native artisans
            and share their exquisite creations with the world. This endeavor is
            our gift to you, inviting you to bring a piece of Morocco’s magic
            and charm into your home.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
