"use client";

import "../../Styles/Shoppage.css";
import { useState} from "react";
import { Link } from "react-router-dom";

export default function ShoppCards({
  productsResponse,
  // currentPage,
  // totalPages,
  // handleNextPage,
  // handlePreviousPage,
  // handlePageChange,
  currency,
}) {
  const products = productsResponse || [];
  const productsPerPage = 12; // Show 12 products per page
  const totalPages = Math.ceil(products.length / productsPerPage);
  // const [currentProducts, setCurrentProducts] = useState([]);

  // useEffect(() => {
  //   console.log("Products Response:", productsResponse);
  //   console.log("Current Page:", currentPage);
  //   console.log("Total Pages:", totalPages);

  //   setCurrentProducts(
  //     products.slice(
  //       (currentPage - 1) * productsPerPage,
  //       currentPage * productsPerPage
  //     )
  //   );
  // }, [products, currentPage]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [currentPage, setCurrentPage] = useState(1);  // Track the current page
  const currentProducts = products.slice(
    (currentPage - 1) * productsPerPage,
    currentPage * productsPerPage
  );
  return (
    <div className="bg-white m-4">
      {/* Main Content */}
      <main className="mx-auto max-w-8xl px-4">
        <section aria-labelledby="products-heading" className="pb-24 pt-6">
          <div className="grid grid-cols-1 gap-x-3 gap-y-10 lg:grid-cols-4">
            {/* Product Grid */}
            <div className="lg:col-span-4">
              <div className="productcard container mx-auto flex items-center flex-wrap pt-4 pb-12">
                {currentProducts.length > 0 ? (
                  <div className="w-full mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-items-center gap-y-10 gap-x-10 mt-10 mb-5 p-5">
                    {currentProducts.map((product) => (
                      <Link to={`/product/${product.id}`}>
                        <div className="relative max-w-xs overflow-hidden bg-cover bg-no-repeat">
                          <img
                            src={
                              product.images.length > 0
                                ? product.images[0].url
                                : `https://via.placeholder.com/300?text=${product.name}`
                            }
                            alt={product.name}
                            className="max-w-xs transition duration-300 ease-in-out hover:scale-110"
                          />
                        </div>
                        <div className="pt-3 flex items-center justify-between font-hubballi">
                          <p>{product.name}</p>
                        </div>
                        <p className="pt-3 flex items-center justify-between font-hubballi">
                          {product.base_price
                            ? product.base_price.toFixed(2)
                            : "0.00"}
                          {currency ? currency.toUpperCase() : "MAD"}
                          {product.compare_at_price && (
                            <span className="line-through text-gray-500 ml-2 font-hubballi">
                              {product.compare_at_price.toFixed(2)}
                              {currency ? currency.toUpperCase() : "MAD"}
                            </span>
                          )}
                        </p>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div className="w-full bg-gray-100 mx-auto text-center mt-10 mb-5 p-5">
                    <p className="text-gray-700 font-hubballi text-xl">
                      No products available at the moment. Please check back
                      later.
                    </p>
                  </div>
                )}
              </div>
              {/* Pagination */}
              <div className="w-full border-gray-200 mt-16 text-xl">
  <nav className="pagination flex justify-center text-gray-700 -mt-px">
    {/* Previous Button */}
    <button
      onClick={() => handlePageChange(currentPage - 1)}
      disabled={currentPage === 1}
      className={` p-2 mx-1 ${currentPage === 1 ? 'text-gray-400' : ''}`}
    >
      &lt;
    </button>

    {/* Page Numbers */}
    {[...Array(totalPages)].map((_, index) => (
      <button
        key={index}
        onClick={() => handlePageChange(index + 1)}
        className={`px-4 py-2 mx-1 text-sm font-medium ${
          currentPage === index + 1
            ? 'text-black border-b-2 border-black'
            : 'text-gray-500 hover:text-black border-b-2 border-transparent hover:border-gray-300'
        }`}
      >
        {index + 1}
      </button>
    ))}

    {/* Next Button */}
    <button
      onClick={() => handlePageChange(currentPage + 1)}
      disabled={currentPage === totalPages}
      className={`p-2 mx-1 ${currentPage === totalPages ? 'text-gray-400' : ''}`}
    >
      &gt;
    </button>
  </nav>
</div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}