import React, { useState, useEffect ,useCallback } from "react";
import Navbar from "../../Components/Home/Navbar";
import "../../Styles/Product.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loading from "../../Components/Home/Loading";
function ProductDetailPage({ currency }) {
  const [quantity, setQuantity] = useState(1);
  const [mainImage, setMainImage] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [product, setProduct] = useState(null);
  const [message, setMessage] = useState("");
  const [selectedVariantPrice, setSelectedVariantPrice] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { id } = useParams(); // productId
  const [selectedOptions, setSelectedOptions] = useState({});
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const fetchProductAndRecommendations = useCallback(async () => {
    try {
      // Fetch the product details including category ID
      const productResponse = await axios.get(
        `https://bk.idymorocco.com/api/products/${id}?currency=${currency}`
      );
      const productData = productResponse.data.data;
      console.log("Product Data:", productData); // Log product data
      setProduct(productData);

      if (productData.images?.length > 0) {
        setMainImage(productData.images[0].url);
        setSelectedImage(productData.images[0].url);
      }

      // Fetch all categories
      const categoryResponse = await axios.get(
        "https://bk.idymorocco.com/api/category"
      );
      const allCategories = categoryResponse.data;

      // Find the sub-sub-category by iterating through the categories and sub-categories
      let productCategory = null;
      for (const category of allCategories) {
        for (const subCategory of category.sub_category) {
          const subSubCategory = subCategory.sub_sub_category.find(
            (subSubCat) => subSubCat.id === productData.sub_sub_category_id
          );
          if (subSubCategory) {
            productCategory = category;
            break;
          }
        }
        if (productCategory) break;
      }

      console.log("Product Category:", productCategory); // Log the product category

      if (productCategory) {
        // Fetch all products to filter them by category client-side
        const productsResponse = await axios.get(
          "https://bk.idymorocco.com/api/products"
        );
        let allProducts = productsResponse.data.data; // Access the products from the response

        // Check if the products are inside an object with a `data` field
        if (!Array.isArray(allProducts)) {
          if (allProducts.data && Array.isArray(allProducts.data)) {
            allProducts = allProducts.data; // Set allProducts to the array inside `data`
          } else {
            console.error(
              "Expected an array of products but received:",
              allProducts
            );
            setMessage("Error: Products data is not in the expected format.");
            return; // Exit the function if the data is not as expected
          }
        }

        console.log("All Products:", allProducts); // Log all products

        // Filter products to get only those that belong to the same sub-sub-category
        const recommendedProductsData = allProducts.filter(
          (product) =>
            product.sub_sub_category_id === productData.sub_sub_category_id
        );
        console.log("Recommended Products Data:", recommendedProductsData); // Log the recommended products

        // Set the recommended products state
        setRecommendedProducts(recommendedProductsData);
      } else {
        console.error("Category not found for the current product.");
        setRecommendedProducts([]); // Set empty recommended products if category not found
      }
    } catch (error) {
      console.error("Error fetching product or category information:", error);
      setMessage(`Error: ${error.message}`);
    }
  }, [id, currency]);

  const fetchProductDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://bk.idymorocco.com/api/products/${id}?currency=${currency}`
      );
      const data = response.data.data;
      setProduct(data);

      if (data.images?.length > 0) {
        setMainImage(data.images[0].url);
        setSelectedImage(data.images[0].url);
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
      setMessage(`Error fetching product details: ${error.message}`);
    }
  }, [id, currency]);

  useEffect(() => {
    fetchProductDetails();
    fetchProductAndRecommendations();
  }, [fetchProductDetails, fetchProductAndRecommendations]);

  useEffect(() => {
    // Update the selected variant price based on selected options
    const selectedOption = product?.variants
      ?.flatMap((v) => v.options)
      .find((option) =>
        Object.values(selectedOptions).flat().includes(option.id)
      );

    setSelectedVariantPrice(selectedOption ? selectedOption.price : null);
  }, [selectedOptions, product]);

  const handleVariantClick = useCallback((variant, option) => {
    setSelectedOptions((prevSelectedOptions) => {
      const variantOptions = prevSelectedOptions[variant.variant_name] || [];
      if (variantOptions.includes(option.id)) {
        // Unselect the option
        return {
          ...prevSelectedOptions,
          [variant.variant_name]: variantOptions.filter(
            (id) => id !== option.id
          ),
        };
      } else {
        // Select the option
        return {
          ...prevSelectedOptions,
          [variant.variant_name]: [...variantOptions, option.id],
        };
      }
    });
  }, []);

  const handleImageClick = useCallback((imageSrc) => {
    setMainImage(imageSrc);
    setSelectedImage(imageSrc);
  }, []);

  const incrementQuantity = useCallback(
    () => setQuantity(quantity + 1),
    [quantity]
  );
  const decrementQuantity = useCallback(
    () => quantity > 1 && setQuantity(quantity - 1),
    [quantity]
  );

  const handleAddToCart = useCallback(async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setMessage("User not authenticated. Please log in.");
      return;
    }

    // if (Object.keys(selectedOptions).length === 0) {
    //   setMessage("Please select a size before adding to cart.");
    //   return;
    // }

    try {
      const selectedOption = product.variants
        ?.flatMap((v) => v.options)
        .find((option) => option.price === selectedVariantPrice);

      const url = selectedOption
        ? `https://bk.idymorocco.com/api/carts/add/${id}/${selectedOption.variant_id}/${selectedOption.id}`
        : `https://bk.idymorocco.com/api/carts/add/${id}`;

      console.log("Sending request to:", url);
      console.log("Request body:", { quantity_ordered: quantity });

      const response = await axios.post(
        url,
        { quantity_ordered: quantity },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setMessage("Product added to cart successfully.");
      console.log("Response Data:", response.data);
    } catch (error) {
      if (error.response) {
        console.error("Error adding to cart:", error.response.data);
        setMessage(
          `Error adding product to cart: ${
            error.response.data.message || "Unknown error"
          }`
        );
      } else {
        console.error("Error adding to cart:", error.message);
        setMessage(`Error adding product to cart: ${error.message}`);
      }
    }
  }, [id ,selectedOptions, product, quantity, selectedVariantPrice]);

  const toggleDropdown = useCallback(
    () => setIsDropdownOpen((prev) => !prev),
    []
  );

  if (!recommendedProducts) return <Loading />;
  if (!product) return <Loading />;

  return (
    <>
      <Navbar />
      <div className="font-sans">
        <div className="p-12 lg:max-w-6xl max-w-2xl mx-auto">
          <div className="grid items-start grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
            <div className="w-full lg:sticky top-0 text-center">
              <div className="lg:h-[560px]">
                <img
                  src={
                          product.images.length > 0
                            ? product.images[0].url
                            : `https://via.placeholder.com/300?text=${product.name}`
                        }
                  alt="Product"
                  className="lg:w-11/12 w-full h-full  object-cover "
                />
              </div>

              <div className="flex flex-wrap gap-4 justify-center mx-auto mt-4">
                {product.images?.map((image, index) => (
                  <img
                    key={index}
                    src={image.url}
                    alt={`Product ${index + 1}`}
                    className={`w-20 cursor-pointer  ${
                      selectedImage === image.url ? "outline outline-black" : ""
                    }`}
                    onClick={() => handleImageClick(image.url)}
                  />
                ))}
              </div>
            </div>

            <div className="h-100">
              <div className="flex flex-wrap items-start gap-4 h-50 bg-slate-100 border-white border-[1px]">
                <div className="w-full sm:w-1/2 p-2 sm:p-4">
                  <h2 className="text-lg sm:text-xl font-hubballi">{product.name}</h2>
                  <p className="text-base sm:text-lg font-hubballi">
                  {currency.toUpperCase() || "MAD"}
                    {selectedVariantPrice
                      ? selectedVariantPrice.toFixed(2)
                      : product.base_price.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="dropdown relative inline-flex mt-5">
                {product.variants &&
                  product.variants.length > 0 && ( // Check if variants exist
                    <button
                      type="button"
                      onClick={toggleDropdown}
                      className="dropdown-toggle inline-flex justify-center items-center gap-2 py-3 px-6 text-sm
                   text-white cursor-pointer font-semibold text-center shadow-xs transition-all duration-500 bg-black"
                    >
                      Select Size
                      <svg
                        className={`w-2.5 h-2.5 text-white ${
                          isDropdownOpen ? "rotate-180" : ""
                        }`}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                        ></path>
                      </svg>
                    </button>
                  )}
                {isDropdownOpen &&
                  product.variants &&
                  product.variants.length > 0 && ( // Ensure dropdown renders if open
                    <div
                      className="dropdown-menu rounded-xl shadow-lg bg-white absolute top-full w-72 mt-2 font-hubballi"
                      aria-labelledby="dropdown-default"
                    >
                      <ul className="py-2">
                        {product.variants.map((variant) => {
                          if (variant.variant_name.toLowerCase() === "size") {
                            return (
                              <li key={variant.id}>
                                <div className="flex flex-col ">
                                  {variant.options.map((option) => (
                                    <button
                                      key={option.id}
                                      onClick={() =>
                                        handleVariantClick(variant, option)
                                      }
                                      className="block py-2 ml-0 hover:bg-gray-100 text-gray-900 font-medium cursor-pointer"
                                    >
                                      {option.option_value}
                                    </button>
                                  ))}
                                </div>
                              </li>
                            );
                          }
                          return null;
                        })}
                      </ul>
                    </div>
                  )}
              </div>

              <div className="bg-slate-50 border-white border-[1px] mt-4">
                <p className=" font-hubballi">{product.description}</p>
              </div>

              <div className="flex justify-end items-end gap-4 mt-50">
                <div className="flex items-center space-x-4 border border-gray-300 rounded mt-10 font-hubballi">
                  <button
                    onClick={decrementQuantity}
                    className="px-2 py-1 text-lg font-semibold text-gray-700"
                  >
                    −
                  </button>
                  <span className="px-4 py-1 text-lg">{quantity}</span>
                  <button
                    onClick={incrementQuantity}
                    className="px-2 py-1 text-lg font-semibold text-gray-700"
                  >
                    +
                  </button>
                </div>
                <button
                  className="bg-black
                   text-white py-2 px-6 text-sm  md:w-2/4 font-hubballi tracking-wide uppercase hover:bg-slate-700"
                  onClick={handleAddToCart}
                >
                  Add to Cart
                </button>
              </div>

              {message && (
  <div role="alert" className="mt-4 text-center bg-gray-500 text-white p-2 rounded font-hubballi">
    {message}
  </div>
)}
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className=" mr-2 ml-2">
      {recommendedProducts.length > 0 && (
  <div className="py-8">
    <h2 className="text-[25px] text-center mb-10 mt-6 ml-9 uppercase font-hubballi">
      You May Also Like
    </h2>
    <div className={`grid gap-8 ${recommendedProducts.length === 1 ? 'justify-center m-2' : 'grid-cols-1 sm:grid-cols-2 md:grid-cols-4'}`}>
    {recommendedProducts.map((recProduct) => (
  <div key={recProduct.id} className="flex flex-col items-center">
    {/* Remove the Link and use button's onClick for a full reload */}
    <button
      className="relative max-w-xs overflow-hidden bg-cover bg-no-repeat"
      onClick={() => window.location.href = `/product/${recProduct.id}`}
    >
      <div className="relative max-w-xs overflow-hidden bg-cover bg-no-repeat">
        <img
          src={
            recProduct.images.length > 0
              ? recProduct.images[0].url
              : `https://via.placeholder.com/300?text=${recProduct.name}`
          }
          className="max-w-xs transition duration-300 ease-in-out hover:scale-110"
          alt={recProduct.name}
        />
      </div>

      {/* Product Name */}
      <div className="pt-3 flex items-center justify-between font-hubballi">
        <p>{recProduct.name}</p>
      </div>

      {/* Product Price */}
      <p className="pt-3 flex items-center justify-between font-hubballi">
        {recProduct.base_price}$
        {recProduct.compare_at_price && (
          <span className="line-through text-gray-500 ml-2 font-hubballi">
            {recProduct.compare_at_price}$
          </span>
        )}
      </p>
    </button>
  </div>
))}

</div>

  </div>
)}
</div>


    </>
  );
}

export default ProductDetailPage;
