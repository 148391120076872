import React from "react";
import rugImage from './../../Assets/rugs2.png';
import "../../Styles/Description.css"; // Adjust the path as necessary
import { Link } from "react-router-dom";
const History = () => {
  return (
    <>
   
    <div className=" Container  grid grid-cols-1 md:grid-cols-2 gap-8 items-center min-h-screen pl-20 mt-10 mb-8">
  {/* Left Column: Text and Button */}
  <div className="space-y-14">  
    {/* Title */}
    <h1 className="text-3xl m-4 text-center text-customBlack tracking-wider font-hubballi uppercase">
      A HISTORY OF PRIDE AND TRADITION 
    </h1>
    <p className="text-customBlack text-2xl tracking-widest leading-relaxed leading-20	 font-hubballi">
      Moroccan rug making is a venerable craft deeply embedded in the country’s culture,
      with its roots tracing back to the Berber people. Passed down through generations,
      this art form is celebrated for its intricate patterns, vibrant colors, and exceptional
      quality...
    </p>

    
    {/* Read More Button */}
    <Link to= "/about-us">
    <button className="bg-customBlack text-white hover:bg-white hover:text-black py-2 mt-6 px-6 rounded-md font-hubballi border border-gray-400">
      Read more
    </button>
    </Link>
  </div>

  {/* Right Column: Image */}
  <div className="">
  <img
    src={rugImage}
    alt="Moroccan Rug"
    className="w-[650px] h-auto object-cover" // Use Tailwind's arbitrary value syntax
  />
</div>

</div>

      </>

  );
};

export default History;