import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Home/Navbar";
import "../../Styles/Description.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Home/Loading";
const CartPage = ({ currency }) => {
  const [cartItems, setCartItems] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true); // Added loading state
  const [exchangeRate, setExchangeRate] = useState(1); // Default exchange rate
  const navigate = useNavigate();
  useEffect(() => {
    fetchCartItems();
  }, [currency]);

  const fetchCartItems = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User not authenticated. Please log in.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get("https://bk.idymorocco.com/api/carts", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          currency: currency,
        },
      });
      if (response.data && response.data.data) {
        setCartItems(response.data.data);
        setExchangeRate(response.data.exchangeRate || 1);
        setError("");
      } else {
        setError("Failed to fetch cart items.");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setError("An error occurred while fetching the cart items.");
    } finally {
      setLoading(false);
    }
  };

  const increaseQuantity = (index) => {
    const newCartItems = [...cartItems];
    newCartItems[index].quantity_ordered += 1;
    setCartItems(newCartItems);
    updateCartItem(newCartItems[index]);
  };

  const decreaseQuantity = (index) => {
    const newCartItems = [...cartItems];
    if (newCartItems[index].quantity_ordered > 1) {
      newCartItems[index].quantity_ordered -= 1;
      setCartItems(newCartItems);
      updateCartItem(newCartItems[index]);
    }
  };

  const updateCartItem = async (item) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User not authenticated. Please log in.");
      return;
    }

    try {
      const response = await axios.put(
        `https://bk.idymorocco.com/api/carts/update/${item.id}`,
        item,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Update response:", response);
      if (
        response.status === 200 &&
        response.data &&
        response.data.status === 200
      ) {
        setError("");
        fetchCartItems(); // Refresh cart items
      } else {
        setError("Failed to update cart item.");
      }
    } catch (error) {
      console.error("Error updating cart item:", error);
      setError("An error occurred while updating the cart item.");
    }
  };

  const deleteCartItem = async (id) => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("User not authenticated. Please log in.");
      return;
    }

    try {
      const response = await axios.delete(
        `https://bk.idymorocco.com/api/carts/remove/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Delete response:", response);
      if (response.status === 200 || response.status === 204) {
        setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
        setError("");
      } else {
        setError("Failed to delete cart item.");
      }
    } catch (error) {
      console.error("Error deleting cart item:", error);
      setError("An error occurred while deleting the cart item.");
    }
  };

  const handleCheckout = () => {
    navigate("/checkout");
  };  
  return (
    <div>
      <Navbar cartItemsCount={cartItems.length} />
      <div className="container mx-auto px-4 md:px-8 lg:pl-32 lg:pr-32 py-8">
        {/* Cart Title */}
        <h1 className="text-3xl font-hubballi mb-4 text-center">CART</h1>

        {/* Show loading spinner or message */}
        {loading ? (
          <div className="text-center py-4">
            <div className="loader"></div> 
            <Loading />
          </div>
        ) : (
          <>
            {/* Cart Table */}
            <div className="cartline flex justify-between flex-wrap text-lg font-hubballi uppercase py-2">
              <span className="w-1/3 sm:w-auto">Product</span>
              <span className="w-1/3 sm:w-auto mr-4">Quantity</span>
              <span className="w-1/3 sm:w-auto text-right">Total</span>
            </div>

            {error && <p className="text-red-500 text-center">{error}</p>}

            {Array.isArray(cartItems) && cartItems.length > 0 ? (
              cartItems.map((item, index) => (
                <div
                  key={item.id}
                  className="flex justify-between items-center py-4 border-b flex-wrap"
                >
                  <div className="flex items-center space-x-2">
                    <img
                      src={
                        item.product.images && item.product.images.length > 0
                          ? item.product.images[0].url
                          : `https://via.placeholder.com/300?text=${item.product.name}`
                      }
                      alt={`Product ${item.product_id}`}
                      className="w-24 h-24 object-cover"
                    />
                    <div>
                      <h3 className="text-sm w-40 font-hubballi uppercase">
                        {item.product.name}
                      </h3>
                      <p className="text-sm text-gray-600 font-hubballi">
                        {/* {item.price} */}
                        
                      {(
                        item.price *
                        exchangeRate
                      ).toFixed(2)}
                      {currency.toUpperCase() || "MAD"}
                      </p>
                    </div>
                  </div>
                  <div className="space-y-2 mr-40">
                    <div className="flex items-center space-x-2">
                      <button
                        className="text-xl px-2 py-1 font-hubballi"
                        onClick={() => decreaseQuantity(index)}
                      >
                        -
                      </button>
                      <input
                        type="text"
                        value={item.quantity_ordered}
                        readOnly
                        className="border w-12 text-center"
                      />
                      <button
                        className="text-xl px-2 py-1 font-hubballi"
                        onClick={() => increaseQuantity(index)}
                      >
                        +
                      </button>
                    </div>
                    <div className="pt-2 text-lg">
                      <div className="line-hover bl-4">
                        <button
                          onClick={() => {
                            deleteCartItem(item.id);
                          }}
                          className="text-black pl-8 font-hubballi"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="w-full sm:w-auto text-right font-hubballi">
                    <span>
                      
                      {(
                        item.price *
                        item.quantity_ordered *
                        exchangeRate
                      ).toFixed(2)}
                      {currency.toUpperCase() || "MAD"}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p
                role="alert"
                className="mt-4 text-center bg-gray-500 text-white p-2 rounded"
              >
                No items in the cart.
              </p>
            )}

            {/* Cart Total */}
            <div className="text-right">
              <div className="mb-4">
                <span className="text-lg font-hubballi uppercase">Total:</span>
                <span className="text-lg font-hubballi ml-2">
                
                  {cartItems
                    .reduce((total, item) => {
                      return (
                        total +
                        item.price *
                          item.quantity_ordered *
                          exchangeRate
                      );
                    }, 0)
                    .toFixed(2)}
                    {currency.toUpperCase() || "MAD"}
                </span>
              </div>
              <h4 className="text-sm font-hubballi">
                Shipping & taxes calculated at Checkout
              </h4>
              <div className="mt-6">
                <button
                  onClick={handleCheckout}
                  className="bg-black text-white px-6 py-3 uppercase text-sm font-hubballi"
                >
                  Checkout
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CartPage;
