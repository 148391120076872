import React from "react";
import "../../Styles/Blogpage.css"; // Adjust the path to your CSS file
import Header from "../../Components/Home/Header"; // Adjust the path as necessary
import Navbar from "../../Components/Home/Navbar"; // Adjust the path as necessary
import rugs from "../../Assets/rugs.png";
import ahwach from "../../Assets/ahwach.png";
import product from "../../Assets/Product.png";
import kasbah from "../../Assets/kasbah.jpg";

const Blogpage = () => {
  return (
    <div>

      <Navbar />
      <div className="blog-page">
        <header className="blog-header">
          <h1>OUR BLOG</h1>
        </header>
        <div className="blog-content">
          <div className="blog-article-large">
            <img src={rugs} alt="rugs" className="blog-image-large" />
            <div className="blog-text-overlay">
              <h2>YOUR GUIDE TO CREATING A COSY AUTUMN HOME IN 5 EASY STEPS</h2>
              <a href="#read-more-large" className="read-more-button">
                READ MORE
              </a>
            </div>
          </div>
          <div className="blog-article-small-container">
            <div className="blog-article-small">
              <img
                src={ahwach}
                alt="BERBER ‘AHWACH"
                className="blog-image-small"
              />
              <div className="blog-article-content">
                <h2>BERBER 'AHWACH'</h2>
                <p>
                  The Amazigh, often referred to as the 'free people', are the
                  indigenous inhabitants of a vast region in North Africa...
                </p>
                <a href="#read-more" className="read-more">
                  Read more
                </a>
              </div>
            </div>
            <div className="blog-article-small">
              <img src={product} alt="Crafts" className="blog-image-small" />
              <div className="blog-article-content">
                <h2>5 SIMPLE WAYS TO COZY UP YOUR HOME FOR AUTUMN</h2>
                <p>
                  Discover the beauty of traditional crafts that have been
                  passed down through generations...
                </p>
                <a href="#read-more" className="read-more">
                  Read more
                </a>
              </div>
            </div>
            <div className="blog-article-small">
              <img src={kasbah} alt="Kasbah" className="blog-image-small" />
              <div className="blog-article-content">
                <h2>Exploring Moroccan Markets</h2>
                <p>
                  Take a journey through the bustling markets of Morocco, where
                  every corner reveals a new treasure...
                </p>
                <a href="#read-more" className="read-more">
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogpage;
